<template lang="pug">
  .logo.d-flex.align-center(
    v-if="siteConfig" 
    @click="navigateHome"
  )
    img.logo(
      :src="siteConfig.global.logo" 
      :alt="siteConfig.global.logoAlt"
    )
    h2.text-white.logo-text(v-if="siteConfig.isNarrative") narrative
</template>

<script>

import ThemeMixin from '@/shared-components/ThemeMixin'
export default {
  mixins: [ThemeMixin],
  methods: {
    navigateHome() {
      if (this.$route.fullPath !== '/') {
        this.$router.push('/')
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.logo
  display: flex
  position: relative
  &:hover
    cursor: pointer  
    opacity: 0.85
  img
    display: block
    margin-right: 1rem
    display: flex
    align-items: center
    height: 1.75rem
    @media (max-width: $breakpoint-md)
      height: 1.25rem
      margin-right: 0.5rem
  h2
    margin-bottom: 0.3125rem  
    font-size: 2rem
    line-height: 1
    font-weight: 500
    letter-spacing: 0.1rem
    @media (max-width: $breakpoint-md)
      font-size: 1.75rem
    @media (max-width: $breakpoint-md)
      font-size: 1.5rem  
  
    
</style>