<template lang="pug">
v-app
  router-view(v-if="isAccessRoute")
  div.d-flex.flex-column(v-else style="height:100%")
    TheMenu
    .main.flex-1.flex-grow-1
      notification
      v-content(style="padding: 0rem")
        router-view(:key="$route.fullPath")
    TheFooter(v-if="showFooter")
</template>

<script>

import 'vue-multiselect/dist/vue-multiselect.min.css'
import(/* webpackPreload: true */ "@/styles/index.sass")
import TheMenu from '@/components/menu/TheMenu.vue'
import TheFooter from '@/shared-components/global/TheFooter'
import Notification from '@/shared-components/global/Notification'
import { mapGetters, mapActions } from 'vuex'
import StructuredData from '@/plugins/structured-data'
import { VTextField, VSelect, VSwitch, VExpansionPanel, VExpansionPanels } from 'vuetify/lib'
import siteConfig from './utils/siteConfig'
import { useSessionTimeout } from './utils/useSessionTimeout'

export default {
  components: { Notification, TheMenu, TheFooter, VTextField, VSelect, VSwitch, VExpansionPanel, VExpansionPanels },
  setup() {
    const { logoutTimer } = useSessionTimeout()
    return { logoutTimer }
  },
  metaInfo() {
    return {}
  },
  data() {
    return {
      accessRoutes: ['login', 'register', 'request-reset-password', 'reset-password', 'terms']
    }
  },
  computed: {
    ...mapGetters(['hostname', 'getOAUrl', 'user']),
    isAccessRoute() {
      return this.accessRoutes.indexOf(this.$route.name) !== -1
    },
    showFooter() {
      return this.$store.getters.showFooter;
    }
  },
  watch: {
    '$store.getters.user': {
      handler() {
        if (this.$store.getters.user) {
          let _hsq = window._hsq = window._hsq || []
          _hsq.push(['identify', {
            email: this.$store.getters.user.email,
          }])
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.$store.dispatch('initAuthInfo', {openApiBaseUrl: this.getOAUrl})
    siteConfig.getSiteConfig().then(config => {
      StructuredData.insertStructuredData('site')
      StructuredData.insertStructuredData('org')
      StructuredData.insertStructuredData('logo')
      if (config.config && config.config.favicon) {
        const favicon = document.getElementById("favicon")
        favicon.href = config.config.favicon
      }
      this.setSiteConfig(config)
      this.setHostname(siteConfig.getHostname())
    })
  },
  methods: {
    ...mapActions(['setHostname', 'setSiteConfig', 'requestToken', 'initAuthInfo']),
  }
}
</script>

<style lang="sass">
  
</style>

<style lang="sass" scoped>
</style>