<template lang="pug">
  .mobile-menu(:style="{backgroundColor: backgroundColor}")
    v-list(
      nav
      dense
    )
      v-list-item(
        to="/products"
        @click="navItemClicked"
      )
        v-list-item-content
          .item.text-primary-light Data Streams
      v-list-item(
        to="/attributes"
        @click="navItemClicked"
      )
        v-list-item-content
          .item.text-primary-light Attributes
      v-list-item(
        to="/apps"
        @click="navItemClicked"
      )
        v-list-item-content
          .item.text-primary-light Apps
      v-list-item(
        to="/cart"
        @click="navItemClicked"
      )
        v-list-item-content
          .item.text-primary-light Cart
      v-list-item(
        v-if="$_userMixin_currentUser"
        to="/subscriptions"
        @click="navItemClicked"
      )
        v-list-item-content
          .item.text-primary-light Subscriptions
      v-list-item(
        v-if="$_userMixin_currentUser"
        to="/settings/profile"
        @click="navItemClicked"
      )
        v-list-item-content
          .item.text-primary-light Account Settings
      v-list-item(
        v-if="!$_themeMixin_isNarrativeDomain && siteConfig && siteConfig.supportEmail"
        @click="contactUsClicked('contactUs')"
        :class="{ disabled: !$_userMixin_currentUser}"
      )
        v-list-item-content
          .item.text-primary-light Contact Us
          .contact-us-tooltip.item(v-if="!$_userMixin_currentUser")
            NioTooltip(
              message="Please login or create an account to contact support."
            )
      v-list-item.no-border(
        v-if="!$_userMixin_currentUser"
        to="/login"
        @click="navItemClicked"
      )
        v-list-item-content
          .item.text-primary-light Login
      v-list-item.no-border(
        v-if="$_userMixin_currentUser"
        @click="logoutUser"
      )
        v-list-item-content
          .item.text-primary-light Logout
      v-list-item.no-border.register(
        v-if="!$_userMixin_currentUser"
        @click="navItemClicked"
      )
        NioButton.register-button(
          v-if="siteConfig && !siteConfig.preventRegistration"
          :size="'normal'"
          :caps="true"
          @click="goToRegister"
        ) Register
</template>

<script>

import UserMixin from "@/shared-components/UserMixin"
import CartMixin from "@/shared-components/CartMixin"
import ThemeMixin from '@/shared-components/ThemeMixin'
import siteConfig from '@/utils/siteConfig'

export default {
  mixins: [UserMixin, CartMixin, ThemeMixin],
  computed: {
    backgroundColor() {
      return this.siteConfig && this.siteConfig.navbar && this.siteConfig.navbar.backgroundColor && !this.siteConfig.isNarrative ? this.siteConfig.navbar.backgroundColor : this.$_themeMixin_getThemeColor('primaryDarker')
    }
  },
  mounted() {
    siteConfig.initCallback(() => {
      if (this.siteConfig && this.siteConfig.navbar && this.siteConfig.navbar.linkColor) {
        this.updateLinkColor()
      }
    })
  },
  methods: {
    navItemClicked(target) {
      this.$emit('navItemClicked')
    },
    contactUsClicked() {
      if (this.$_userMixin_currentUser) {
        this.$router.push('/support')
      }
    },
    goToRegister() {
      this.$router.push('/login')
    },
    logoutUser() {
      this.$_userMixin_logout()
      this.navItemClicked()
      this.$router.push('/products')
    },
    updateLinkColor() {
      const listItemElements = document.querySelectorAll(".mobile-menu .v-list-item")
      listItemElements.forEach(el => {
        if (this.siteConfig.isNarrative) {
          el.setAttribute( 'style', 'box-shadow: inset 0rem -0.0938rem 0rem rgba(75, 95, 167, 0.2) !important' )
        } else {
          el.setAttribute( 'style', `box-shadow: inset 0rem -0.0938rem 0rem ${this.siteConfig.navbar.linkColor} !important` )
        }
      })
      const noBorderlistItemElements = document.querySelectorAll(".mobile-menu .v-list-item.no-border")
      noBorderlistItemElements.forEach(el => {
        el.setAttribute( 'style', 'box-shadow: none !important' )
      })
      const linkElements = document.querySelectorAll(".mobile-menu .v-list-item__content .item")
      linkElements.forEach(el => {
        el.setAttribute( 'style', `color: ${this.siteConfig.navbar.linkColor} !important` )
      })
    }
  }
}
</script>

<style lang="sass">
  .v-navigation-drawer
    width: unset !important
    top: 4.3125rem !important

</style>

<style lang="sass" scoped>
  .item
    font-size: 0.75rem
    line-height: 1.25rem
    letter-spacing: 0.1125rem
    text-transform: uppercase
    font-weight: bold
  .mobile-menu
    width: 20rem
    max-width: 90vw
    height: 100%
    .v-list
      padding-top: 0rem
      ::v-deep a:hover
        text-decoration: none
      .v-list-item
        margin-bottom: 0rem !important
        height: 3.125rem
        border-radius: 0px
        &.register:hover
          cursor: initial
        &.disabled
          pointer-events: none
          &:hover
            cursor: initial
          .text-primary-light
            opacity: 0.5
        ::v-deep .v-list-item__content
          position: relative
          text-align: center

          .nio-tooltip
            position: absolute
            left: 0px
            top: -2px
            width: 100%
            height: 100%
            .activator
              width: 100% !important
              height: 100% !important
              pointer-events: auto
              height: 100% !important
              opacity: 0
              cursor: initial
              &:hover
                cursor: initial
        &.v-list-item--active
          &::before
            opacity: 0
          .v-list-item__content .item
            color: $c-white
        &:not(.register):hover
          .v-list-item__content .item
            color: $c-primary-lighter
      .register-button
        margin: 0 auto
</style>