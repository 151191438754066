const dropdownNavItems = [
  {
    groupName: 'manage',
    groupLabel: "Manage",
    items: [
      {
        name: 'subscriptions',
        label: 'Subscriptions',
        icon: 'display-subscriptions',
        to: '/subscriptions'
      }
    ]
  },
  {
    groupName: 'accountSetttings',
    groupLabel: "Account Settings",
    items: [
      {
        name: 'profile',
        label: 'Your Profile',
        icon: 'display-yourprofile',
        to: '/settings/profile'
      },
      {
        name: 'payment',
        label: 'Payment Methods',
        icon: 'display-payment',
        to: '/settings/payment'
      },
      {
        name: 'apps',
        label: 'Installed Apps',
        icon: 'display-destinations',
        to: '/settings/apps'
      },
      {
        name: 'sources',
        label: 'Sources',
        icon: 'display-sources',
        status: 'coming'
      }
    ]
  },
  {
    groupName: 'support',
    groupLabel: "Support",
    items: [
      {	
        name: 'knowledgeBase',
        label: 'Knowledge Base',
        icon: 'display-help',
        event: 'goToKB'
      },
      {	
        name: 'security',
        label: 'Data Agreement',
        icon: 'display-security',
        to: '/legal/data-purchase-agreement'
      },
      {	
        name: 'privacy',
        label: 'Privacy Policy',
        icon: 'display-privacy',
        event: 'goToPrivacyPolicy'
      },
      {	
        name: 'tos',
        label: 'Terms of Service',
        icon: 'display-list',
        event: 'goToTermsOfService'
      }
    ]
  },
  {
    groupName: 'logout',
    items: [
      {	
        name: 'logout',
        label: 'Logout',
        icon: 'display-logout',
        event: 'localLogout'
      }	
    ]
  }		
]

export { dropdownNavItems }