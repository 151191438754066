<template lang="pug">
  div.menu-container(:style="{backgroundColor: siteConfig ? siteConfig.navbar.backgroundColor : '#010A28'}").d-flex.align-center
    div.nio-container
      .d-none.d-lg-flex.align-center.justify-space-between
        AppLogo
        .menu-links.d-flex.align-center(v-if="siteConfig")
          .public-links
            RouterLink.item(
              to="/products"
              :style="{color: siteConfig.navbar.linkColor}"
            ) {{ siteConfig.navbar.linkText.home}}
            RouterLink.item(
              v-if="$_themeMixin_isNarrativeDomain"
              to="/attributes"
              :style="{color: siteConfig.navbar.linkColor}"
            ) Attributes
            RouterLink.item(
              to="/providers"
              :style="{color: siteConfig.navbar.linkColor}"
              v-if="siteConfig.isNarrative && $_userMixin_currentUser"
            ) Providers
            RouterLink.item(
              to="/apps"
              :style="{color: siteConfig.navbar.linkColor}"
            ) Apps
            RouterLink.item(
              v-if="!$_themeMixin_isNarrativeDomain && siteConfig.supportEmail"
              to="/support"
              :style="{color: siteConfig.navbar.linkColor}"
              :class="{ disabled: !$_userMixin_currentUser}"
            ) Contact Us
            .contact-us-tooltip.item(v-if="!$_themeMixin_isNarrativeDomain && siteConfig.supportEmail && !$_userMixin_currentUser")
              NioTooltip(
                open-on-hover
                message="Please login or create an account to contact support."
              )
          .account-links
            template(v-if="$_userMixin_currentUser")
              router-link.icon-link(to="/subscriptions")
                NioIcon(
                  name="display-subscriptions"
                  size="22"
                  :color="isActiveRoute('/subscriptions') ? iconColor(true) :  iconColor(false)")
              //- router-link.icon-link(to="/subscriptions")
              //-   NioIcon(
              //-     name="display-subscriptions"
              //-     size="22"
              //-     :color="isActiveRoute('/subscriptions') ? '#FFFFFF' : '#849BF4'")
              router-link.icon-link.cart(
                to="/cart"
                :class="{hasproducts: $_cartMixin_cartProductCount > 0}"
              )
                .icon-wrapper
                  svg(width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg")
                    path(d="M8.5 24.968C8.5 25.4653 8.69754 25.9422 9.04917 26.2938C9.40081 26.6455 9.87772 26.843 10.375 26.843C10.8723 26.843 11.3492 26.6455 11.7008 26.2938C12.0525 25.9422 12.25 25.4653 12.25 24.968C12.25 24.4707 12.0525 23.9938 11.7008 23.6422C11.3492 23.2906 10.8723 23.093 10.375 23.093C9.87772 23.093 9.40081 23.2906 9.04917 23.6422C8.69754 23.9938 8.5 24.4707 8.5 24.968V24.968Z" :stroke="isActiveRoute('/cart') ? iconColor(true) : iconColor(false)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round")
                    path(d="M16 24.968C16 25.4653 16.1975 25.9422 16.5492 26.2938C16.9008 26.6455 17.3777 26.843 17.875 26.843C18.3723 26.843 18.8492 26.6455 19.2008 26.2938C19.5525 25.9422 19.75 25.4653 19.75 24.968C19.75 24.4707 19.5525 23.9938 19.2008 23.6422C18.8492 23.2906 18.3723 23.093 17.875 23.093C17.3777 23.093 16.9008 23.2906 16.5492 23.6422C16.1975 23.9938 16 24.4707 16 24.968V24.968Z" :stroke="isActiveRoute('/cart') ? iconColor(true) : iconColor(false)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round")
                    path(d="M4.75 11.093L7.079 18.98C7.16428 19.2994 7.35267 19.5816 7.61488 19.7829C7.87709 19.9842 8.19845 20.0932 8.529 20.093H19.347C19.6781 20.0938 20.0001 19.9851 20.2629 19.7838C20.5258 19.5825 20.7146 19.2999 20.8 18.98L24.038 6.82601C24.1656 6.34695 24.448 5.92349 24.8411 5.62148C25.2343 5.31947 25.7162 5.15583 26.212 5.15601H27.25" :stroke="isActiveRoute('/cart') ? iconColor(true) : iconColor(false)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round")
                    text(v-if="$_cartMixin_cartProductCount > 0" xml:space="preserve" text-anchor="middle" font-family="'Euclid Circular A', sans-serif" font-size="9" id="svg_5" y="50%" x="45%" stroke-opacity="null" stroke-width="0" stroke="null" fill="#f6c244") {{ $_cartMixin_cartProductCount }}
              DropdownNav(
                v-if="dropdownNavItems"
                :user="$_userMixin_currentUser"
                :nav-items="dropdownNavItems"
                :lock-items="{}"
                :hide-items="{}"
                @goToKB="goToKB"
                @goToPrivacyPolicy="goToPrivacyPolicy"
                @goToTermsOfService="goToTermsOfService"
                @localLogout="localLogout"
              )
                template(
                  #viewing-as
                  v-if="isAdmin && siteConfig && siteConfig.isNarrative"
                )
                  v-divider
                  .viewing-as
                    NioAutocomplete.viewing-as-menu(
                      v-if="companies.length"
                      v-model="selectedCompany"
                      label="Viewing As"
                      :value="selectedCompany"
                      :items="companies"
                      item-value="id"
                      item-text="text"
                      outlined
                      attach-to-parent
                    )
            template(v-else)
              router-link.icon-link.cart(
                to="/cart"
                :class="{hasproducts: $_cartMixin_cartProductCount > 0}"
              )
                .icon-wrapper
                  svg(width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg")
                    path(d="M8.5 24.968C8.5 25.4653 8.69754 25.9422 9.04917 26.2938C9.40081 26.6455 9.87772 26.843 10.375 26.843C10.8723 26.843 11.3492 26.6455 11.7008 26.2938C12.0525 25.9422 12.25 25.4653 12.25 24.968C12.25 24.4707 12.0525 23.9938 11.7008 23.6422C11.3492 23.2906 10.8723 23.093 10.375 23.093C9.87772 23.093 9.40081 23.2906 9.04917 23.6422C8.69754 23.9938 8.5 24.4707 8.5 24.968V24.968Z" :stroke="isActiveRoute('/cart') ? iconColor(true) : iconColor(false)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round")
                    path(d="M16 24.968C16 25.4653 16.1975 25.9422 16.5492 26.2938C16.9008 26.6455 17.3777 26.843 17.875 26.843C18.3723 26.843 18.8492 26.6455 19.2008 26.2938C19.5525 25.9422 19.75 25.4653 19.75 24.968C19.75 24.4707 19.5525 23.9938 19.2008 23.6422C18.8492 23.2906 18.3723 23.093 17.875 23.093C17.3777 23.093 16.9008 23.2906 16.5492 23.6422C16.1975 23.9938 16 24.4707 16 24.968V24.968Z" :stroke="isActiveRoute('/cart') ? iconColor(true) : iconColor(false)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round")
                    path(d="M4.75 11.093L7.079 18.98C7.16428 19.2994 7.35267 19.5816 7.61488 19.7829C7.87709 19.9842 8.19845 20.0932 8.529 20.093H19.347C19.6781 20.0938 20.0001 19.9851 20.2629 19.7838C20.5258 19.5825 20.7146 19.2999 20.8 18.98L24.038 6.82601C24.1656 6.34695 24.448 5.92349 24.8411 5.62148C25.2343 5.31947 25.7162 5.15583 26.212 5.15601H27.25" :stroke="isActiveRoute('/cart') ? iconColor(true) : iconColor(false)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round")
                    text(v-if="$_cartMixin_cartProductCount > 0" xml:space="preserve" text-anchor="middle" font-family="'Euclid Circular A', sans-serif" font-size="9" id="svg_5" y="50%" x="45%" stroke-opacity="null" stroke-width="0" stroke="null" fill="#f6c244") {{ $_cartMixin_cartProductCount }}
              NioButton.item(
                normal-tertiary
                @click="$router.push('/login')"
              ) Log In
              NioButton.item(
                v-if="siteConfig && !siteConfig.preventRegistration"
                normal-primary
                @click="$router.push('/login')"
              ) Register
      .mobile-links.d-flex.d-lg-none.align-center.justify-space-between
        transition(
          name="fade"
          mode="out-in"
        )
            NioIcon.menu-icon(
              v-if="!showDrawer"
              key="2"
              :color="iconColor(true)"
              name="display-hamburger"
              size="36"
              @click="showDrawer = !showDrawer"
            )
            NioIcon.menu-icon.close(
              v-if="showDrawer"
              key="1"
              :color="iconColor(true)"
              name="display-close"
              size="20"
              @click="showDrawer = !showDrawer"
            )
        AppLogo
        router-link.icon-link.cart(
          to="/cart"
          :class="{hasproducts: $_cartMixin_cartProductCount > 0}"
        )
          .icon-wrapper
            svg(width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg")
              path(d="M8.5 24.968C8.5 25.4653 8.69754 25.9422 9.04917 26.2938C9.40081 26.6455 9.87772 26.843 10.375 26.843C10.8723 26.843 11.3492 26.6455 11.7008 26.2938C12.0525 25.9422 12.25 25.4653 12.25 24.968C12.25 24.4707 12.0525 23.9938 11.7008 23.6422C11.3492 23.2906 10.8723 23.093 10.375 23.093C9.87772 23.093 9.40081 23.2906 9.04917 23.6422C8.69754 23.9938 8.5 24.4707 8.5 24.968V24.968Z" :stroke="isActiveRoute('/cart') ? iconColor(true) : iconColor(false)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round")
              path(d="M16 24.968C16 25.4653 16.1975 25.9422 16.5492 26.2938C16.9008 26.6455 17.3777 26.843 17.875 26.843C18.3723 26.843 18.8492 26.6455 19.2008 26.2938C19.5525 25.9422 19.75 25.4653 19.75 24.968C19.75 24.4707 19.5525 23.9938 19.2008 23.6422C18.8492 23.2906 18.3723 23.093 17.875 23.093C17.3777 23.093 16.9008 23.2906 16.5492 23.6422C16.1975 23.9938 16 24.4707 16 24.968V24.968Z" :stroke="isActiveRoute('/cart') ? iconColor(true) : iconColor(false)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round")
              path(d="M4.75 11.093L7.079 18.98C7.16428 19.2994 7.35267 19.5816 7.61488 19.7829C7.87709 19.9842 8.19845 20.0932 8.529 20.093H19.347C19.6781 20.0938 20.0001 19.9851 20.2629 19.7838C20.5258 19.5825 20.7146 19.2999 20.8 18.98L24.038 6.82601C24.1656 6.34695 24.448 5.92349 24.8411 5.62148C25.2343 5.31947 25.7162 5.15583 26.212 5.15601H27.25" :stroke="isActiveRoute('/cart') ? iconColor(true) : iconColor(false)" stroke-width="1" stroke-linecap="round" stroke-linejoin="round")
              text(v-if="$_cartMixin_cartProductCount > 0" xml:space="preserve" text-anchor="middle" font-family="'Euclid Circular A', sans-serif" font-size="9" id="svg_5" y="50%" x="45%" stroke-opacity="null" stroke-width="0" stroke="null" fill="#f6c244") {{ $_cartMixin_cartProductCount }}
      v-navigation-drawer(
        v-model="showDrawer"
        :left="true"
        fixed
        clipped
        hide-overlay
        @navItemClicked="close"
      )
        TheMobileMenu
</template>

<script>
import UserMixin from "@/shared-components/UserMixin";
import CartMixin from "@/shared-components/CartMixin";
import AppLogo from '../AppLogo'
import TheMobileMenu from './TheMobileMenu'
import sl from '@/utils/serviceLayer'
import { NioIcon } from '@narrative.io/tackle-box'
import DropdownNav from '@narrative.io/tackle-box/src/components-private/DropdownNav'
import { dropdownNavItems } from './dropdownNavItems'
import ThemeMixin from '@/shared-components/ThemeMixin'
import siteConfig from '@/utils/siteConfig'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { AppLogo, DropdownNav, TheMobileMenu, NioIcon },
  mixins: [UserMixin, CartMixin, ThemeMixin],
  data: () => ({
    showDrawer: false,
    companies: [],
    showMenu: false,
    dropdownNavItems: null,
    selectedCompany: undefined
  }),
  computed: {
    ...mapGetters(['getOAUrl']),
    viewingAsCompany() {
      return this.companies.find(company => company.id === parseInt(this.$_userMixin_contextualCompanyId))
    },
    isAdmin() {
      return this.$_userMixin_currentUser !== null && this.$_userMixin_currentUser.role === 99
    },
    userInitials() {
      let nameSplit = this.$_userMixin_currentUser.name.split(' ')
      let initials = ''
      nameSplit.forEach(el => {
        initials = initials + el.substring(0, 1)
      })
      return initials !== '' ? initials : 'U'
    }
  },
  watch: {
    selectedCompany(val) {
      if (!val && companies.length > 0) {
        this.selectedCompany = val.find(company => company.id === parseInt(this.$_userMixin_contextualCompanyId))
      }
      if (this.selectedCompany.id !== parseInt(this.$_userMixin_contextualCompanyId)) {
        this.$_userMixin_selectCompanyContext(val)
        this.$_cartMixin_emptyCart()
        this.$router.go()
      }
    }
  },
  mounted() {
    siteConfig.initCallback(() => {
      this.makeDropdownNavItems()
    })
    this.initAuthInfo({openApiBaseUrl: this.getOAUrl, callback: this.userInitialized})

  },
  methods: {
    ...mapActions(['initAuthInfo']),
    userInitialized() {
      if (this.$_userMixin_currentUser !== null && this.$_userMixin_currentUser.role == 99) {
        sl.get('/api/companies/summaries')
          .then(resp => {
            this.companies = resp.data.map(company => {
              return {
                ...company,
                text: `${company.name} (${company.id})`
              }
            })
            if (this.companies.length > 0) {
              this.selectedCompany = this.companies.find(company => company.id === parseInt(this.$_userMixin_contextualCompanyId))
            }
          })
      }
    },
    makeDropdownNavItems() {
      let navItems = dropdownNavItems
      if (!this.$_themeMixin_isNarrativeDomain) {
        navItems = navItems.filter(navGroup => navGroup.groupName !== 'support')
        navItems[0].items = navItems[0].items.filter(item => item.name !== 'listManager')
        navItems[1].items = navItems[1].items.filter(item => item.name !== 'apps')
      }
      this.dropdownNavItems = navItems
    },
    iconColor(isActive) {
      if (this.siteConfig && this.siteConfig.navbar.icons) {
        return isActive ? this.siteConfig.navbar.icons.activeColor : this.siteConfig.navbar.icons.color
      } else {
        return isActive ? '#FFFFFF' : '#849BF4'
      }
    },
    open() {
      this.showMenu = true
    },
    close() {
      this.showMenu = false
      this.showDrawer = false
    },
    isActiveRoute(route) {
      return this.$route.path === route
    },
    localLogout() {
      this.$_userMixin_logout()
    },
    goToKB() {
      window.open('http://kb.narrative.io/data-streams', '_blank');
    },
    goToPrivacyPolicy() {
      window.open('https://www.narrative.io/privacy-policy', '_blank')
    },
    goToTermsOfService() {
      window.open('https://www.narrative.io/legal/terms-of-service', '_blank')
    }
  }
};
</script>

<style lang="sass">
  .v-navigation-drawer
    width: unset !important
</style>

<style lang="sass" scoped>
.close-section
  display: flex
  padding: 1.25rem
  justify-content: flex-end
a, a:hover
  text-decoration: none
.v-menu__content
  border-radius: 1rem
.v-card.v-sheet
  width: 23.75rem
.menu-container
  +nio-page
  height: 4.3125rem
  color: white
.mobile-logo
  display: flex
  h2
    margin-bottom: 0.3125rem
  .mobile-icons
    display: flex
    justify-content: flex-end
    .icon-link
      width: 2rem
      height: 2rem
      margin-left: 1.25rem
      padding: 0.3125rem
      &.user-link
        padding: 0rem
        position: relative
        &:hover
          cursor: pointer
      &.cart
        margin-right: 1.875rem
        .icon-wrapper
          position: relative
          svg
            position: absolute
            top: -0.375rem

.menu-links
  display: flex
  justify-content: space-between
  flex-grow: 2
  margin-left: 1rem
  .item
    text-transform: uppercase
    // color: #BDCAF9
    font-weight: 600
    font-size: 0.875rem
    letter-spacing: 0.075rem
    margin: 0 1rem
    white-space: nowrap
    align-self: center
    &:last-child
      margin-right: 0px
  a.item:hover
    text-decoration: none
    filter: brightness(115%)
  .item.v-btn
    color: #fff
  .item.disabled
    opacity: 0.5
    pointer-events: none !important
    position: relative
    &:hover
      cursor: initial
  .contact-us-tooltip
    align-self: center
    width: 0px !important
    margin: 0px
    position: relative
    display: inline
    .nio-tooltip
      position: absolute
      left: -125px
      top: -2px
      ::v-deep .activator
        width: 102px !important
        height: 19px !important
        pointer-events: auto
        height: 100% !important
        opacity: 0
        cursor: initial
        &:hover
          cursor: initial
  .item, .user-item
    @media (max-width: $breakpoint-lg)
      font-size: 0.75rem
  .public-links
    margin-bottom: 0.125rem
  .account-links
    display: flex
    .icon-link
      width: 2rem
      height: 2rem
      margin-left: 1.25rem
      padding: 0.3125rem
      &.user-link
        padding: 0rem
        position: relative
        transition: opacity 0.1s ease
        &:hover
          cursor: pointer
          opacity: 0.8

      &.cart
        margin-right: 0.625rem
        .icon-wrapper
          position: relative
          svg
            position: absolute
            top: -0.3125rem
      .avatar
        background-color: $c-coral-light
        border-radius: 0.25rem
        width: 2rem
        height: 2rem
        padding: 0.3125rem
        display: flex
        .h3
          letter-spacing: 0rem
        +nio-center-content
    .activator
      width: 2rem
      height: 2rem
      margin-left: 1.25rem
.menu-icon
  margin-top: 0px
  &:hover
    cursor: pointer
  &:not(.close)
    margin-right: -3px
  &.close
    margin-left: 6px
    margin-right: 6px
.fade-enter-active,
.fade-leave-active
    transition: opacity .08s ease
.fade-enter,
.fade-leave-to
    opacity: 0
.viewing-as
  padding: 1.25rem 1.875rem 1.25rem 1.875rem
</style>